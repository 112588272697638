// @ts-nocheck
import { useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Markdown from "markdown-to-jsx";

import { accountThunks } from "actions-thunks/account-thunks";
import { documentThunks } from "actions-thunks/documents-thunks";
import { setWireImageAC } from "actions-thunks/actions";
import constants from "lib/constants";
import AccordionComponent from "../ui-kit/accordion/Accordion";
import VerticalStepper from "../ui-kit/stepper/VerticalStepper";
import SendPerformanceBondStep from "./SendPerformanceBondStep";
import UploadButton from "../edit-profile/UploadButton";
import { utilityFunctions } from "../utilities/utilityFunctions";
import AttachmentImage from "../ui-kit/attachment/AttachmentImage";
import DateRadio from "../ui-kit/radio/DateRadio";
import DateInput from "../ui-kit/date-input/DateInput";
import { requestsThunks } from "actions-thunks/requests-thunks";

import "./account-setup.scss";

const today = moment.utc(new Date()).format("YYYY-MM-DD");
const convertDate = (date) =>
  !date ? today : moment.utc(date).format("YYYY-MM-DD");
const fullMonthDate = (date = convertDate()) => {
  return moment.utc(date).format("MMMM DD, YYYY.");
};

const undoneStatuses = [
  "Incomplete",
  "Received",
  "RequireInformation",
  "Updated",
];

const AccountSetup = ({
  portalWorkflowType,
  wireSent,
  setWireSent,
  wireSentClicked,
  onSideItemClick,
  setWireSentClicked,
  setPopupState,
}) => {
  const { currentAccount, statusHistory, wireImage, wiresList } = useSelector(
    (state) => state.accounts
  );
  const { requestsItems } = useSelector((state) => state.requests);
  const { currentDocument, documents } = useSelector(
    (state) => state.documents
  );
  const [currentStep, setCurrentStep] = useState({
    step: 2,
    name: "Application Review",
  });
  const [startDate, setStartDate] = useState("");
  const [notValidDate, setNotValidDate] = useState(false);
  const [wireFAQClicked, setWireFAQClicked] = useState(false);
  const [instructionsClicked, setInstructionsClicked] = useState(false);
  const [wireWordClicked, setWireWordClicked] = useState(false);
  const [newImageUploaded, setNewImageUploaded] = useState(false);
  const [startTradeValue, setStartTradeValue] = useState("date");
  const [dateIsSet, setDateIsSet] = useState(null);
  const dispatch = useDispatch();
  const isPDF = useMemo(
    () => wireImage?.image.split("/")[1].split(";")[0] === "pdf",
    [wireImage]
  );

  const {
    FormStatusTypes,
    RequestTypes,
    FormStatusTexts,
    RequestFormStatusTypes,
  } = constants;

  const setStatusFor2ndStep = useMemo(() => {
    if (currentAccount) {
      switch (currentAccount.status) {
        case "Received":
        case "Incomplete":
        case "Updated":
          return FormStatusTypes.PENDING_STATUS;
        case "RequireInformation":
          return FormStatusTypes.ONHOLD_STATUS;
        case "Approved":
        case "AccountCreated":
        case "AccountEnabled":
        case "AccountSuspended":
          return FormStatusTypes.APPROVED_STATUS;
        case "Rejected":
          return FormStatusTypes.REJECTED_STATUS;
        default:
          return "none";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  const checkWiresListStatus = (status) => {
    return (
      !!wiresList?.length && wiresList[wiresList.length - 1].status === status
    );
  };

  // set step according to wire/account statuses
  useEffect(() => {
    if (currentAccount !== null) {
      if (wiresList !== null) {
        //wire status is PENDING = set step 3
        if (checkWiresListStatus("pending")) {
          setCurrentStep(steps[2]);
          setWireSent(false);
        }
        //wire status is CLAIMED = set step 3
        if (checkWiresListStatus("claimed")) {
          if (wiresList[wiresList.length - 1]?.claim?.confirmation) {
            dispatch(
              accountThunks.getWireConfirmationImageTC(
                currentAccount._id,
                wiresList[wiresList.length - 1]._id
              )
            );
          }
          setCurrentStep(steps[2]);
          setWireSent(true);
        }
        //wire status is RECEIVED = set step 4
        if (checkWiresListStatus("received")) {
          setWireSent(true);
          setCurrentStep(steps[3]);
        }
      }
      if (currentAccount.status === "AccountCreated") {
        if (
          requestsItems &&
          requestsItems[RequestTypes.TRADING_ACCESS]?.find(
            (r) => r.status === RequestFormStatusTypes.OPEN
          )?.ext?.action === "ENABLE"
        ) {
          const foundItem = requestsItems[RequestTypes.TRADING_ACCESS]?.find(
            (r) => r.status === RequestFormStatusTypes.OPEN
          );
          setStartDate(convertDate(foundItem.schedule.duedate));
          const dateType = moment(
            convertDate(foundItem.schedule.duedate)
          ).isSame(new Date(), "day");
          setStartTradeValue(dateType ? "soon" : "date");
          setDateIsSet(true);
          //set step 6
          setCurrentStep(steps[5]);
        } else {
          //set step 5
          setCurrentStep(steps[4]);
        }
      }
      if (
        currentAccount.status === "AccountEnabled" ||
        currentAccount.status === "AccountSuspended"
      ) {
        onSideItemClick("accountoverview");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wiresList, currentAccount, requestsItems, dispatch]);

  // get and set countries from backend
  useEffect(() => {
    dispatch(accountThunks.setEntityTC("country"));
  }, [dispatch]);

  useEffect(() => {
    if (documents === null) {
      dispatch(documentThunks.getListDocumentsTC());
    }
  }, [documents, dispatch]);

  // get account statuses history &&
  //get wires list if any exists
  useEffect(() => {
    if (currentAccount) {
      // get status history
      if (currentAccount?.status) {
        dispatch(accountThunks.getAccountStatusHistoryTC(currentAccount._id));
      }
      //if application has APRROVED status = get wire list
      if (!undoneStatuses.includes(currentAccount?.status)) {
        dispatch(accountThunks.getWiresListTC(currentAccount._id));
      }
      //set start trading date if exists
      dispatch(
        requestsThunks.getAccountRequestsTC({
          accID: currentAccount._id,
          type: RequestTypes.TRADING_ACCESS,
        })
      );
    }
  }, [currentAccount, RequestTypes.TRADING_ACCESS, dispatch]);

  const getDocument = (value) => {
    return documents.find(
      (d) => (d.linkname && d.linkname === value) || d.id === value
    );
  };

  const onWireInstructionsClick = (e) => {
    e.preventDefault();
    setWireFAQClicked(true);
    dispatch(
      documentThunks.getCurrentDocumentTC(getDocument("wire_instructions"))
    );
  };
  const onMarketInstructionsClick = (e) => {
    e.preventDefault();
    setInstructionsClicked(true);
    dispatch(
      documentThunks.getCurrentDocumentTC(
        getDocument("617a7d4d2dba046041e9a015")
      )
    );
  };
  const onWireWordClick = (e) => {
    e.preventDefault();
    setWireWordClicked(true);
  };

  const renderFAQBlock = () => {
    if (wireFAQClicked || instructionsClicked) {
      return (
        <div className="faq-opened-dialog">
          <div className="black-block" />
          <div className="faq-dialog-block">
            <header>
              {instructionsClicked
                ? "Managing Market Data"
                : "Wire Instructions"}
            </header>
            <div className="faq-dialog">{renderDocument(wireFAQClicked)}</div>
            <button
              className="faq-button"
              onClick={() => {
                setWireFAQClicked(false);
                setInstructionsClicked(false);
              }}
            >
              OK
            </button>
          </div>
        </div>
      );
    }
  };

  const renderWireSentBlock = () => {
    const onYesClick = (e) => {
      e.preventDefault();
      setWireSentClicked(false);
      setNewImageUploaded(false);
      const wire = wiresList[wiresList.length - 1];
      dispatch(
        accountThunks.claimWireTC(currentAccount._id, wireImage, {
          wireID: wire._id,
          amount: wire.amount.pending,
        })
      );
    };
    const onRemoveClick = (fromRemove) => {
      if (fromRemove) {
        dispatch(setWireImageAC(null));
      } else {
        setWireWordClicked(false);
        setWireSent(false);
        dispatch(
          accountThunks.removeWireConfirmationTC(
            currentAccount._id,
            wiresList[wiresList.length - 1]
          )
        );
      }
    };
    const onChangeClick = (e) => {
      e.preventDefault();
      const foundButton = document.getElementById("main-upload-image-button");
      foundButton.click();
    };
    const onCancelClick = (fromCancel) => {
      if (fromCancel) {
        setWireSentClicked(false);
        dispatch(setWireImageAC(null));
      } else {
        setWireWordClicked(false);
        if (newImageUploaded) {
          dispatch(
            accountThunks.sendWireConfirmationImageTC(
              currentAccount._id,
              wiresList[wiresList.length - 1]._id,
              wireImage
            )
          );
        }
      }
    };
    if (wireSentClicked)
      return (
        <div className="wire-opened-dialog">
          <div className="black-block" />
          <div className="wire-dialog-block">
            <header>Wire Confirmation</header>
            <div className="wire-dialog">
              <span>Do you confirm that a wire was sent?</span>
              <hr />
              {wireImage && (
                <div className="wire-image-block">
                  <img
                    className="wire-image pad-btn"
                    src={
                      isPDF
                        ? `${process.env.PUBLIC_URL}/pdf-icon.png`
                        : wireImage.image
                    }
                    alt="wire"
                  />
                  <div className="wire-image-buttons">
                    <button
                      className="wire-button upload-wire-button"
                      onClick={() => onRemoveClick(true)}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/close-red.png`}
                        alt="close"
                      />
                      Remove
                    </button>
                    <button
                      className="wire-button upload-wire-button"
                      onClick={onChangeClick}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/open-folder.png`}
                        alt="open"
                      />
                      Change
                    </button>
                  </div>
                </div>
              )}
              <UploadButton
                show={!wireImage}
                setNewImageUploaded={setNewImageUploaded}
                actionType="fee"
                setPopupState={setPopupState}
              />
              <hr />
            </div>
            <div className="wire-dialog-bottom-buttons fixed-button-size">
              <button id="wire-confirm-button-2" className="wire-button" onClick={onYesClick}>
                Yes, confirm
              </button>
              <button
              id="wire-cancel-button-2"
                className="wire-button wire-button-transparent"
                onClick={() => onCancelClick(true)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      );
    if (wireWordClicked) {
      return (
        <div className="wire-opened-dialog">
          <div className="black-block" />
          <div className="wire-dialog-block">
            <header>Wire Confirmation</header>
            <div className="wire-dialog">
              <span className="wire-dialog-subheader">
                You confirmed the wire was sent{" "}
              </span>
              <span>{getWireSentStatusDate()}</span>
              <hr />
              {wireImage && (
                <div className="wire-image-block">
                  <img
                    className="wire-image"
                    src={
                      isPDF
                        ? `${process.env.PUBLIC_URL}/pdf-icon.png`
                        : wireImage.image
                    }
                    alt="wire"
                  />
                </div>
              )}
              <UploadButton
                show={!wireImage}
                setNewImageUploaded={setNewImageUploaded}
                actionType="fee"
                setPopupState={setPopupState}
              />
              <hr />
              <span>
                If you did not send this wire or would like to change any
                information, please click below to start over.
              </span>
            </div>
            <div className="wire-dialog-bottom-buttons">
              <button
                className="wire-button wire-button-red"
                onClick={() => onRemoveClick(false)}
              >
                Remove Wire Confirmation
              </button>
              <button
                className="wire-button wire-button-transparent"
                onClick={() => onCancelClick(false)}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderDocument = (isWire) => {
    if (currentDocument?.format === "MARKUP") {
      const forWireTitle = `## Funding Your Account \n`;

      // Define the Beneficiary Note
      const beneficiaryNote = `<br>Beneficiary Note: ${currentAccount?.reference?.client || "N/A"}`;
      const additionalLine = `<br><span style="font-size: smaller;">* must be included in the transfer to avoid delays in processing</span>`;

      const modifiedBody = currentDocument?.body.replace(
        /Beneficiary Address:.*\n/,
        (match) => `${match.trim()}${beneficiaryNote}${additionalLine}`
      );

      return (
        <Markdown
          children={`${isWire ? forWireTitle : ""} ${modifiedBody}` || ""}
          options={{
            overrides: {
              Accordion: {
                component: AccordionComponent,
              },
              img: {
                component: AttachmentImage,
                props: {
                  currentDocument: currentDocument?.id,
                },
              },
            },
          }}
        />
      );
    } else return currentDocument?.body || "";
  };

  const getWireSentStatusDate = (received = false, noHours = false) => {
    if (
      !!wiresList?.length &&
      (wiresList[wiresList.length - 1]?.claim ||
        wiresList[wiresList.length - 1]?.received)
    ) {
      const statusDate = received
        ? wiresList[wiresList.length - 1]["received"]?.date
        : wiresList[wiresList.length - 1]["received"]?.date ||
          wiresList[wiresList.length - 1]["claim"]?.date;
      const transformedFormat = utilityFunctions.transformToStepDateView(
        statusDate,
        noHours
      );
      const textOn = `on ${transformedFormat}`;
      return textOn;
    }
  };

  //performance bond parts
  const renderSendPerformanceBondTexts = useMemo(() => {
    if (currentAccount?.status === FormStatusTypes.REJECTED_STATUS) {
      return FormStatusTexts.REJECTED;
    }
    if (currentStep.step < 3) {
      return FormStatusTexts[3].STEP_3_NOT_APPROVED;
    }
    if (currentStep.step === 3) {
      if (checkWiresListStatus("pending")) {
        return (
          <div>
            <div>
              <span className="bold">Step 1: </span>
              {FormStatusTexts[3].STEP_3_APPROVING_1.split(",")[0]}
              <span>
                {utilityFunctions
                  .currencyFormatter()
                  .format(wiresList[wiresList.length - 1].amount.pending)}
              </span>
              {FormStatusTexts[3].STEP_3_APPROVING_1.split(",")[1]}
              <button
                className="wire-instruction-button"
                onClick={onWireInstructionsClick}
              >
                Wire Instructions
              </button>
              {FormStatusTexts[3].STEP_3_APPROVING_1.split(",")[2]}
            </div>
            <div>
              <span className="bold">Step 2: </span>
              {FormStatusTexts[3].STEP_3_APPROVING_2}
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div>
              {FormStatusTexts[3].STEP_3_CLAIMED_1.split(",")[0]}
              <button
                className="wire-instruction-button"
                onClick={onWireWordClick}
              >
                wire
              </button>
              {FormStatusTexts[3].STEP_3_CLAIMED_1.split(",")[1]}
              <span>{getWireSentStatusDate(false, "noHours")}</span>
            </div>
            <div>{FormStatusTexts[3].STEP_3_CLAIMED_2}</div>
          </div>
        );
      }
    }
    if (currentStep.step > 3) {
      return (
        <div>
          <div>
            &#183; {FormStatusTexts[3].STEP_3_APPROVED_1}
            <span>{getWireSentStatusDate(false, "noHours")}</span>
          </div>
          <div>
            &#183; {FormStatusTexts[3].STEP_3_APPROVED_2}
            <span>{getWireSentStatusDate(true, "noHours")}</span>
          </div>
        </div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, currentStep, wiresList]);

  const setPerformanceBondTitle = useMemo(() => {
    if (checkWiresListStatus("received")) {
      const PB = utilityFunctions
        .currencyFormatter()
        .format(wiresList[wiresList.length - 1].amount.received);
      return `Performance Bond of ${PB} was received`;
    }
    return "Send Performance Bond";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, currentStep, wiresList]);

  //trading platform parts
  const renderTradingPlatformTexts = useMemo(() => {
    const generateTPtitle = (value) => {
      if (value === "Sterling") return "Sterling Trader®";
      return value;
    };
    if (currentAccount?.status === FormStatusTypes.REJECTED_STATUS) {
      return FormStatusTexts.REJECTED;
    }
    if (currentStep.step < 4) {
      return FormStatusTexts[4].STEP_4_NOT_APPROVED;
    }
    if (currentStep.step === 4) {
      return FormStatusTexts[4].STEP_4_APPROVING;
    }
    return (
      <div>
        <div>{FormStatusTexts[4].STEP_4_APPROVED_1}</div>
        <div>
          {`${
            FormStatusTexts[4].STEP_4_APPROVED_2.split(",")[0]
          } ${generateTPtitle(currentAccount.software)} ${
            currentAccount.softwareType
          }.`}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, currentStep]);

  const renderTradingPlatformTitle = useMemo(() => {
    if (currentStep.step < 5) {
      return "Trading Platform Creation";
    }
    return "Trading Platform created";
  }, [currentStep]);

  //market data parts
  const renderMarketDataTexts = useMemo(() => {
    //tp = trading platform
    const currentTP = currentAccount?.software;
    if (currentAccount?.status === FormStatusTypes.REJECTED_STATUS) {
      return FormStatusTexts.REJECTED;
    }
    if (currentStep.step < 5) {
      return FormStatusTexts[5].STEP_5_NOT_APPROVED;
    }
    if (currentStep.step === 5) {
      if (currentTP !== "Sterling") {
        return FormStatusTexts[5].STEP_5_APPROVING_NOT_ST;
      } else {
        const here_1 =
          FormStatusTexts[5].STEP_5_APPROVING_ST_1.split("here")[0];
        const here_2 =
          FormStatusTexts[5].STEP_5_APPROVING_ST_1.split("here")[1];
        return (
          <div>
            <div>
              {here_1}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://sem.sterlingtradingtech.com/auth/"
              >
                here
              </a>{" "}
              {here_2}
            </div>
            <div>
              {
                FormStatusTexts[5].STEP_5_APPROVING_ST_2.split(
                  "the instructions"
                )[0]
              }
              <span
                className="market-data-instructions"
                onClick={onMarketInstructionsClick}
              >
                the instructions.
              </span>
            </div>
            <div>{FormStatusTexts[5].STEP_5_APPROVING_ST_3}</div>
            <ul>
              <li>{FormStatusTexts[5].STEP_5_APPROVING_ST_3_a}</li>
              <li>{FormStatusTexts[5].STEP_5_APPROVING_ST_3_b}</li>
              <li>{FormStatusTexts[5].STEP_5_APPROVING_ST_3_c}</li>
            </ul>
          </div>
        );
      }
    }
    if (currentStep.step > 5) {
      return FormStatusTexts[5].STEP_5_APPROVED;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, currentAccount]);

  //start trading part
  const renderStartTradingText1 = useMemo(() => {
    if (currentAccount?.status === FormStatusTypes.REJECTED_STATUS)
      return FormStatusTexts.REJECTED;
    if (currentStep.step < 6) return FormStatusTexts[6].STEP_6_NOT_APPROVED;
    if (currentStep.step === 6) {
      if (dateIsSet) {
        if (moment(convertDate(startDate)).isSame(new Date(), "day")) {
          return (
            <div className="bold">{FormStatusTexts[6].STEP_6_ENABLE_TODAY}</div>
          );
        }
        const text =
          startTradeValue === "soon" ? (
            <div>
              <div className="bold">
                {FormStatusTexts[6].STEP_6_SOON_IS_SET}
              </div>
              <div className="bold">{FormStatusTexts[6].STEP_6_DATE_FEES}</div>
            </div>
          ) : (
            <div>
              <div className="bold">
                {`${
                  FormStatusTexts[6].STEP_6_DATE_IS_SET.split(",")[0]
                }${fullMonthDate(startDate)}`}
              </div>
              <div className="bold">{FormStatusTexts[6].STEP_6_DATE_FEES}</div>
              <div>{FormStatusTexts[6].STEP_6_APPROVING_4}</div>
            </div>
          );
        return text;
      } else
        return (
          <div>
            <div>{FormStatusTexts[6].STEP_6_APPROVING_1}</div>
            <div>{FormStatusTexts[6].STEP_6_APPROVING_2}</div>
          </div>
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, currentStep, dateIsSet, startTradeValue]);

  //start trading part
  const renderStartTradingText2 = useMemo(() => {
    if (currentAccount?.status === FormStatusTypes.REJECTED_STATUS) return "";
    if (currentStep.step < 6) return FormStatusTexts[6].STEP_6_NOT_APPROVED;
    if (currentStep.step === 6) {
      if (dateIsSet) return;
      if (notValidDate) {
        return (
          <span className="not-valid-form">
            {notValidDate.wrong === "down"
              ? "Selected date can't be earlier than today"
              : "Selected date can't be later than 1st day of the next month"}
          </span>
        );
      }
      if (startTradeValue === "soon")
        return `${
          FormStatusTexts[6].STEP_6_APPROVING_DATE.split(",")[0]
        } ${fullMonthDate()}`;
      if (startTradeValue === "date") {
        if (startDate) {
          return (
            <div>
              {FormStatusTexts[6].STEP_6_APPROVING_DATE.split(",")[0]}{" "}
              {fullMonthDate(startDate)}
            </div>
          );
        }
        return "Please select your start date.";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, currentStep, startTradeValue, startDate, dateIsSet]);
  //start trading part
  const renderStartTradingDate = useMemo(() => {
    const changeDataHandler = (e) => {
      setStartDate(e.target.value);
      setStartTradeValue("date");
      if (
        moment(e.target.value).startOf("day") >
        moment().add(3, "M").startOf("month")
      )
        return setNotValidDate({ wrong: "up" });
      if (moment(e.target.value).startOf("day") < moment().startOf("day"))
        return setNotValidDate({ wrong: "down" });
      return setNotValidDate(false);
    };
    const onSelectClick = (e) => {
      e.preventDefault();
      const sendData = {
        duedate: startTradeValue === "soon" ? convertDate() : startDate,
      };
      const idToSend = requestsItems[RequestTypes.TRADING_ACCESS].find(
        (r) => r.status === RequestFormStatusTypes.OPEN
      )?._id;
      dispatch(
        requestsThunks.updateAccountRequestsTC(
          currentAccount._id,
          idToSend,
          sendData
        )
      );
      setDateIsSet(true);
    };
    const onRadioChange = (id) => {
      if (id === "soon") {
        setStartDate("");
        setNotValidDate(false);
      }
      if (id === "date") {
        setStartDate(today);
      }
      setStartTradeValue(id);
    };
    const setButtonDisabled = () => {
      if (startTradeValue) {
        if (startTradeValue === "soon") return false;
        if (startTradeValue === "date" && !!startDate.length) return false;
        return true;
      }
      return true;
    };
    const onChangeStartDateClick = (e) => {
      e.preventDefault();
      if (moment(startDate).isSame(new Date(), "day")) {
        setNotValidDate(false);
        setStartTradeValue("soon");
      }
      setDateIsSet(null);
    };
    if (currentStep.step === 6) {
      if (
        currentAccount?.status === "AccountEnabled" ||
        currentAccount.status === "AccountSuspended"
      )
        return;

      if (dateIsSet) {
        return (
          <div className="start-trade-date-block">
            <button onClick={onChangeStartDateClick} id="start-trade-date-change-button">Change</button>
          </div>
        );
      }

      return (
        <div className="start-trade-date-block">
          <div>
            {!currentAccount?.startTrading && (
              <div>{FormStatusTexts[6].STEP_6_APPROVING_3}</div>
            )}
            <div className="date-options-block">
              <DateRadio
                label={
                  <DateInput
                    name="start"
                    type="date"
                    min={convertDate()}
                    max={moment()
                      .add(3, "M")
                      .startOf("month")
                      .format("YYYY-MM-DD")}
                    value={startDate || ""}
                    onChange={changeDataHandler}
                    label="Select date"
                  />
                }
                checked={startTradeValue === "date"}
                onChangeHandler={onRadioChange}
                name="date-value"
                id="date"
              />
              <DateRadio
                name="as-soon-as-possible"
                label="as soon as possible"
                checked={startTradeValue === "soon"}
                onChangeHandler={onRadioChange}
                id="soon"
              />
            </div>
          </div>
          {notValidDate && (
            <span className="not-valid-form">
              {notValidDate.wrong === "down"
                ? "Selected date can't be earlier than today"
                : "Selected date can't be later than 1st day of the next month"}
            </span>
          )}

          <button
            disabled={notValidDate || setButtonDisabled()}
            onClick={onSelectClick}
            id="confirm-date-block-button"
          >
            Confirm
          </button>
        </div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, startDate, startTradeValue, dateIsSet, dispatch]);

  const steps = [
    {
      step: 1,
      name: "Application",
      status: "Submitted",
      text: "Thank you for completing your trading application!",
    },
    {
      step: 2,
      name: "Application",
      status: setStatusFor2ndStep,
      text: FormStatusTexts[2][setStatusFor2ndStep],
    },
    {
      step: 3,
      name: setPerformanceBondTitle,
      status: "",
      text: renderSendPerformanceBondTexts,
      utilityField: (
        <SendPerformanceBondStep
          wireSent={wireSent}
          currentStep={currentStep}
          setPopupState={setPopupState}
          setWireSentClicked={setWireSentClicked}
        />
      ),
    },
    {
      step: 4,
      name: renderTradingPlatformTitle,
      status: "",
      text: renderTradingPlatformTexts,
    },
    {
      step: 5,
      name: "Market Data",
      status: "",
      text: renderMarketDataTexts,
    },
    {
      step: 6,
      status: "",
      name:
        currentAccount?.status === FormStatusTypes.REJECTED_STATUS
          ? FormStatusTexts.REJECTED
          : "Start Trading!",
      text: renderStartTradingText1,
      text2: renderStartTradingText2,
      utilityField: renderStartTradingDate,
    },
  ];

  const renderAccountEnabledModal = () => {
    if (currentAccount?.status === "AccountEnabled")
      return (
        <div className="acc-enable-window">
          <div className="dialog-block-background" />
          <div className="dialog-block">
            <h4>
              Congratulations, {currentAccount.personal.firstName}{" "}
              {currentAccount.personal.lastName}
            </h4>
            <div className="dialog-body-block">
              <div className="mb-3">
                Your trading platform is now enabled and ready to trade!
              </div>
              <ul className="mb-3">
                <span>Please use this Trader Portal for the following:</span>
                <li>see your current balance and risk settings,</li>
                <li>request a change to your risk settings,</li>
                <li>communicate with our Support Team,</li>
                <li>receive important/urgent info,</li>
                <li>etc.</li>
              </ul>
              <div className="mb-3">
                We are constantly expanding the range <br /> of the capabilities
                of the tools we offer you.
              </div>
              <div className="mb-3">
                Read more about the Portal{" "}
                <a
                  target="_blank"
                  href={`${window.location.origin}/documents/617a7ac30b22ab563defcb5a`}
                  rel="noreferrer"
                >
                  in our FAQ
                </a>
                .
              </div>
              <div>Zimtra Team</div>
              <button
                className="main-button dialog-button"
                onClick={() => onSideItemClick("accountoverview")}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      );
  };

  if (portalWorkflowType !== null && currentAccount !== null)
    return (
      <div className="account-setup-wrapper">
        <h4 className="account-setup-header" id="accountsetup">
          Account Setup
        </h4>
        <VerticalStepper
          steps={steps}
          statusHistory={statusHistory}
          currentAccount={currentAccount}
          checkWiresListStatus={checkWiresListStatus}
          requestsItems={requestsItems?.[RequestTypes.TRADING_ACCESS]}
          wiresList={wiresList}
          portalWorkflowType={portalWorkflowType}
          currentStep={currentStep}
          wireSent={wireSent}
          dateIsSet={dateIsSet}
        />
        {renderFAQBlock()}
        {renderWireSentBlock()}
        {renderAccountEnabledModal()}
      </div>
    );
  return (
    <div className="account-setup-wrapper">
      <Spinner className="spinner" animation="grow" />
    </div>
  );
};

export default AccountSetup;
