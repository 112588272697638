import { useContext, useEffect, useRef, useState } from "react";
import { Modal, Button, Icon } from "@lynx/ui-components";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

import Chat from "./Chat";
import ConfirmationModal from "ui/components/modals/confirmation/ConfirmationModal";
import { supportTableUtils } from "ui/ui-kit/react-table/supportTableUtils";
import constants from "lib/constants";
import { ModalContext } from "ui/ui-kit/modal/ModalContext";
import { requestsThunks } from "actions-thunks/requests-thunks";
import { getActiveRequestID, getActiveRequest } from "./helpers";
import { requestTableUtils } from "ui/utilities/requestsTableUtils";
import "./modalStyles.scss";

const { tableTimeFormat } = supportTableUtils;

const ChatModal = ({ hideModal, item }) => {
  const { showModal } = useContext(ModalContext);
  const dispatch = useDispatch();
  const { RequestTypes, statusData } = constants;
  const { requestsItems, riskSettingMetadata } = useSelector(
    (state) => state.requests
  );
  const { loading } = useSelector((state) => state.utils);
  const { currentAccount } = useSelector((state) => state.accounts);
  const requestsItemsDataALL = requestsItems?.[RequestTypes.ALL];
  const [activeRequest, setActiveRequest] = useState(item.original);
  const childRef = useRef();

  const setRequestStatus = (status) => {
    dispatch(
      requestsThunks.updateRequestStatusTC({
        accID: currentAccount._id,
        requestID: getActiveRequestID(requestsItemsDataALL, activeRequest.id),
        action: status,
      })
    );
  };

  useEffect(() => {
    const currentRequest = getActiveRequest(
      requestsItemsDataALL,
      activeRequest.id
    );
    // we need format data to make it consistent with the data from props
    currentRequest &&
      setActiveRequest(
        requestTableUtils?.tableFormattedDataForEachElement(
          currentRequest,
          riskSettingMetadata
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestsItems, riskSettingMetadata]);

  const handleHideModal = () => {
    hideModal();
    if (childRef.current) {
      childRef.current.cancelSourceOperation();
    }
  };

  const handleCancel = () => {
    showModal(<ChatModal hideModal={handleHideModal} item={item} />);
  };

  const handleApply = (status) => {
    setRequestStatus(status);
    handleHideModal();
  };

  const handleCancelRequestClick = () => {
    showModal(
      <ConfirmationModal
        isApplyButtonRed
        showEditModal
        closeModal={handleHideModal}
        onApplyClick={() => handleApply("cancel")}
        onCancelClick={handleCancel}
        labels={{
          title: "Cancel market data request?",
          body: "Do you really want to cancel this market data request?",
          buttons: { apply: "Yes", decline: "No" },
        }}
      />
    );
  };

  const handleResolveRequestClick = () => {
    showModal(
      <ConfirmationModal
        showEditModal
        closeModal={handleHideModal}
        onApplyClick={() => handleApply("resolve")}
        onCancelClick={handleCancel}
        labels={{
          title: "Confirm request resolution?",
          body: "Do you really want to mark this request as resolved?",
          buttons: { apply: "Yes", decline: "No" },
        }}
      />
    );
  };

  const showChatButtons = () => {
    const foundRowItemInRequests = requestsItemsDataALL.find(
      (r) => r.sequenceNumber === activeRequest.id
    );

    if (
      (activeRequest.state === statusData.OPEN ||
        activeRequest.state === statusData.NEW_MESSAGE) &&
      !(
        foundRowItemInRequests?.type === RequestTypes.RISKSETTING &&
        foundRowItemInRequests.initiator === "SYSTEM"
      )
    ) {
      return (
        <Button
          size="md"
          buttonStyle="button-variant-fourth"
          icon={<Icon name="x" />}
          buttonClassName="cabinet-secondary-btn cancel-request"
          onClick={handleCancelRequestClick}
          label="Cancel request"
        />
      );
    }

    if (
      activeRequest.category === constants.categoryData.CUSTOM &&
      activeRequest.state === statusData.WAITING_FOR_CLIENT
    ) {
      return (
        <Button
          size="md"
          buttonStyle="button-variant-second"
          icon={<Icon name="check" />}
          buttonClassName="cabinet-primary-btn resolved-request"
          onClick={handleResolveRequestClick}
          label="Request resolved"
        />
      );
    }
  };

  return (
    <div className="chat-modal-wrapper">
      <div className="modal-window">
        <Modal
          className="cabinet-modals chat-modal"
          styleType="zimtra-primary"
          overlayClassName="chat-overlay"
          isOpen={true}
          hideBlur
          hasCloseIcon={true}
          onClickOutside={handleHideModal}
        >
          <Modal.Header
            className="modal-inner-header"
            onClose={handleHideModal}
            title={
              <p className="heading">
                Request ID: {activeRequest.id} | {activeRequest.summary}
              </p>
            }
          />
          <Modal.Body className="modal-inner-body">
            {loading && (
              <div className="loader">
                {" "}
                <Spinner className="spinner" animation="grow" />
              </div>
            )}
            <div className="modal-item-info">
              <table>
                <thead>
                  <tr>
                    <th>Current status:</th>
                    <th>Updated:</th>
                    <th>Created:</th>
                    <th>Category:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{activeRequest.state}</td>
                    <td>{tableTimeFormat(activeRequest.updated)}</td>
                    <td>{tableTimeFormat(activeRequest.created)}</td>
                    <td>{activeRequest.customCategory}</td>
                  </tr>
                </tbody>
              </table>
              {showChatButtons()}
            </div>
            <div className="chat-outer-container">
              <Chat
                itemID={activeRequest.id}
                requestsItems={requestsItems}
                ref={childRef}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default ChatModal;
