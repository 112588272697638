import { Button } from "react-bootstrap";

const SendPerformanceBondStep = ({
  currentStep,
  setWireSentClicked,
  wireSent,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    setWireSentClicked(true);
  };

  if (currentStep.step === 3 && wireSent !== null && !wireSent) {
    return (
      <div className="refundable-fee-upload">
        <Button onClick={handleClick} disabled={wireSent} id="i-sent-wire-button">
          &nbsp;
          I sent wire
        </Button>
      </div>
    );
  }
  return null;
};

export default SendPerformanceBondStep;
