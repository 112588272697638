import React from "react";
import cn from "classnames";
import "./DateRadio.scss";

const DateRadio = ({
  label,
  checked,
  disabled,
  onChangeHandler,
  name,
  id,
  ...props
}) => {
  const inputId = `radio_${name}`;

  return (
    <label
      htmlFor={inputId}
      className={cn("radioButtonItem", {
        disabled: disabled,
      })}
    >
      <input
        type="radio"
        className="radio-input"
        name={label}
        onChange={() => onChangeHandler(id)}
        checked={checked}
        id={inputId}
        {...props}
      />
      <span>{label}</span>
    </label>
  );
};

export default DateRadio;
