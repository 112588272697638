import React from "react";
import { Button } from "react-bootstrap";
import { POPUP_SUPPORT } from "../../../reducers/types";

import "./popup.scss";

const Popup = ({ popupState, onPopupClickHandler, fieldIsEditing }) => {
  const renderPopupButtons = () => {
    switch (popupState.type) {
      case "saves":
        return (
          <div className="popup-buttons">
            <Button size="sm" className="default-button sm-button" variant="primary" onClick={()=>onPopupClickHandler("save")}>
              {fieldIsEditing.type === POPUP_SUPPORT ? "Send" : "Save"}
            </Button>
            <Button size="sm" className="default-button sm-button" variant="primary" onClick={()=>onPopupClickHandler("notsave")}>
              {fieldIsEditing.type === POPUP_SUPPORT ? "Don't Send" : "Don't Save"}
            </Button>
            <Button size="sm" className="default-button sm-button" variant="primary" onClick={()=>onPopupClickHandler("stay")}>
              Stay
            </Button>
          </div>
        );
      case "remove-attachment":
      case "remove-adm-file":
      case "download-adm-file":
        return (
          <div className="popup-buttons">
            <Button size="sm" variant="primary" onClick={()=>onPopupClickHandler(popupState.type)}>
              {popupState.type === "download-adm-file" ? "Download" : "Remove" }
            </Button>
            <Button size="sm" variant="primary" onClick={()=>onPopupClickHandler("stay")}>
              Cancel
            </Button>
          </div>
        );
      case "account-wire":
        return (
          <div className="popup-buttons">
            <Button id="wire-confirm-button-1" size="sm" variant="primary" onClick={()=>onPopupClickHandler(popupState.type)}>
              Yes, confirm
            </Button>
            <Button id="wire-cancel-button-1" size="sm" variant="primary" onClick={()=>onPopupClickHandler("stay")}>
              Cancel
            </Button>
          </div>
        )
      case "information":
        return (
          <div className="popup-buttons">
            <Button size="sm" variant="primary" onClick={()=>onPopupClickHandler("stay")}>
              Got it
            </Button>
          </div>
        )
      default:
        break;
    }
  };

  if (popupState.opened)
    return (
      <div className="popup-wrapper">
        <div className="popup-black-area" />
        <div className="popup-area">
          <div className="popup-message">{popupState.message}</div>
          {renderPopupButtons()}
        </div>
      </div>
    );
  return null;
};

export default Popup;
