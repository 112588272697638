import {useState, useRef, useEffect} from "react";
import { Modal, Button, Dropdown, Input } from "@lynx/ui-components";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import cn from "classnames";
import { object, string } from "yup";

import constants from "lib/constants";

import { Upload } from "ui/ui-kit/upload/Upload";

import "./styles.scss";
import {requestsAPI} from "../../../api/requests-api";

const CustomRequestSchema = object({
  subject: string().required("Required field"),
  message: string().required("Required field"),
  category: string().required('Required field')
});

const { RequestTypes } = constants;

const CreateCustomRequestModal = ({
  closeModal,
  updateCategory,
  categories,
  activeCategory,
  submitModal,
}) => {
  const formRef = useRef();
  const { loading } = useSelector((state) => state.utils);
  const [localAttachments, setLocalAttachments] = useState([]);
  const defaultCategory = categories.find(
    (category) => category.value === activeCategory.value
  );

  const [requestCategories, setRequestCategories] = useState([]);

  const fetchCustomCategories = async () => {
    try {
      const res = await requestsAPI.getCustomRequestCategories();

      setRequestCategories(res.data.items.map(i => ({
        label: i.name,
        value: i.name
      })));
    } catch (e) {}
  }

  useEffect(() => {
    fetchCustomCategories();
  }, []);

  const formik = useFormik({
    initialValues: {
      category: "",
      subject: "",
      message: "",
    },
    validationSchema: CustomRequestSchema,
    onSubmit: (values) => {
      const formData = new FormData();

      for (const file of localAttachments) {
        formData.append("attachments[]", file, file.name);
      }

      submitModal({
        body: {
          type: RequestTypes.INQUIRY,
          ext: {
            ...values,
          },
          formData,
        },
        type: RequestTypes.INQUIRY,
        isCustomForm: true,
      });
    },
  });

  const requestCategoryChange = (value) => {
    formik.setValues({
      ...formik.values,
      category: value
    }, true);
  }

  return (
    <div className="modal-window">
      <Modal
        className="custom-request-modal cabinet-modals"
        overlayClassName="request-overlay"
        styleType="zimtra-primary"
        isOpen={true}
        hideBlur
        hasCloseIcon={false}
        onClickOutside={closeModal}
      >
        <Modal.Header
          className="modal-inner-header"
          onClose={closeModal}
          title={<h2 className="zimtra-h2">New Request</h2>}
        />
        <Modal.Body className="modal-inner-body">
          <div className="d-flex align-items-top modal-row first">
            <div className="left-side">Category:</div>
            <div>
              <Dropdown
                options={categories}
                className="request-dropdown"
                defaultValue={defaultCategory.value}
                placeholder="Select category"
                onChange={updateCategory}
              />
            </div>
          </div>
          <hr />
          <form onSubmit={formik.handleSubmit} ref={formRef}>
            <div className="d-flex align-items-top modal-row">
              <div className="left-side">Category:</div>
              <div className={cn({"error-field": formik.errors.category})}>
                <Dropdown
                    options={requestCategories}
                    className="request-dropdown select-request-category-dropdown"
                    style={{width: '100%'}}
                    onChange={requestCategoryChange}
                    placeholder="Select a category" />
                {formik.errors.category ? (
                    <div className="error-text">{formik.errors.category}</div>
                ) : null}
              </div>
            </div>

            <div className="d-flex align-items-top modal-row">
              <div className="left-side">Subject:</div>
              <div className={cn({ "error-field": formik.errors.subject })}>
                <Input
                  variant="zimtra-primary"
                  placeholder="Write subject of your request"
                  maxLength={50}
                  labelClassName="cabinet-input"
                  size="short"
                  id="subject"
                  name="subject"
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                />
                {formik.errors.subject ? (
                  <div className="error-text">{formik.errors.subject}</div>
                ) : null}
              </div>
            </div>

            <div className="d-flex align-items-top modal-row request-row">
              <div className="left-side">Request:</div>
              <div className={cn({ "error-field": formik.errors.message })}>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Describe your request"
                  maxLength="1500"
                  className="cabinet-textarea"
                  onChange={formik.handleChange}
                  value={formik.values.message}
                />
                {formik.errors.message ? (
                  <div className="error-text">{formik.errors.message}</div>
                ) : null}
              </div>
            </div>

            <div className="d-flex align-items-top upload-row">
              <Upload
                handleChange={(items) => setLocalAttachments(items)}
                tooltip={{
                  tooltipClassName: "upload-tooltip",
                  tooltipContentClassName: "upload-tooltip-content",
                  content: (
                    <span>
                      Max file size: 5 MB.
                      <br />
                      Max number of attachments: 5. <br />
                      Allowed filetypes:
                      <br />
                      <ul>
                        <li>text: PDF, CSV, XLS, XLSX, TXT</li>
                        <li>images: JPEG, JPG, PNG, GIF</li>
                        <li>video: MP4, MOV, WMV, AVI, FLV</li>
                      </ul>
                    </span>
                  ),
                }}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="modal-inner-footer d-flex justify-content-center">
          <Button
            size="md"
            buttonStyle="button-variant-second"
            buttonClassName="cabinet-primary-btn"
            disabled={loading || !formik.isValid || !formik.dirty}
            onClick={formik.handleSubmit}
            label="Create"
          />
          <Button
            size="md"
            buttonStyle="button-variant-fourth"
            buttonClassName="cabinet-secondary-btn"
            onClick={closeModal}
            label="Cancel"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateCustomRequestModal;
